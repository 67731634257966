import {showSnackbar, navigate} from './app.js';
import { finishRecoveryMode } from './auth';

export const PASSWORD_SET_REQUEST = 'PASSWORD_SET_REQUEST';
export const PASSWORD_SET_FAILED = 'PASSWORD_SET_FAILED';
export const PASSWORD_SET_SUCCESS = 'PASSWORD_SET_SUCCESS';


var full = location.protocol+'//'+location.hostname+(location.port ? ':3001': '');


export const updateOwnPW = (pwJSON, recovermode) => (dispatch) => {
  dispatch({
    type: PASSWORD_SET_REQUEST
  });
  let link = '/app/api/user/pw';
  if (recovermode) {
    link = '/app/_user/pw';
    //pwJSON = {...pwJSON, token: token}
  }
  return fetch(`${full}${link}`,{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.authToken}`,
    },
    body: JSON.stringify(pwJSON),
  })
  .then(
    response => response.json(),
    error => {
      console.log('An error occurred.', error);
    }
  )
  .then(result => {
      dispatch({
        type: PASSWORD_SET_SUCCESS,
        result
      });
      // Snackbar anzeigen
      dispatch(showSnackbar('Passwort gespeichert.')).then(
        () => {
          if (recovermode) {
            // Hack um Snackbar abzuwarten
            window.setTimeout(
              () => {
                window.history.pushState({}, '', '/');
                dispatch(navigate('/'));
                dispatch(finishRecoveryMode());
              }, 3000);
          }

        }
      );
    }
  )
  .catch(err => {
    console.log(err);
    dispatch({
      type: PASSWORD_SET_FAILED,
      error: err
    })
  })

}