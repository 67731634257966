import { html } from 'lit-element';
import { store } from '../store.js';
import { connect } from 'pwa-helpers/connect-mixin.js';
import { PageViewElement } from './page-view-element.js';

// These are the shared styles needed by this element.
import { SharedStyles } from './shared-styles.js';

import '@vaadin/vaadin-checkbox/vaadin-checkbox.js';
import '@vaadin/vaadin-button/vaadin-button.js';
import '@vaadin/vaadin-select/vaadin-select.js';
import '@vaadin/vaadin-text-field/vaadin-text-field.js';
import '@vaadin/vaadin-tabs';
import 'collaborne-polymer-password-strength/password-strength.js';
import { updateOwnPW } from '../actions/user.js';


class BenutzerprofilView extends connect(store)(PageViewElement) {

  constructor() {
    super();
    this.pw = '';
    this.pwConfirm = '';
    this.recovermode = false;
    this.token = '';
    window.zxcvbn = zxcvbn;
  }

  static get styles() {
    return [
      SharedStyles
    ];
  }

  static get properties() {
    return {
      pw: { type: String },
      pwConfirm: { type: String },
      recovermode: { type: Boolean },
      token: { type: String }
    }

  }



  render() {
    return html`
      <style>

        vaadin-text-field {
          max-width: 100%;
        }

        #pwFrm {
          padding: 10px 20px;
        }
      </style>
        <div>
        ${!this.recovermode ?
          html`
            <app-toolbar app-title>
              <div main-title>Benutzerprofil</div>
            </app-toolbar>`
          : '' }
        </div>
              <form id="pwFrm" >
                <vaadin-form-layout>
                    <P>Die Mindestlänge für Passwörter beträgt 8 Zeichen.</P><br>
                    <vaadin-password-field  name="Passwort" id="PasswortField"
                      @input="${this.pwListener}"
                      label="Passwort" minlength="8" maxlength="32" required reveal-button-hidden></vaadin-password-field><br>
                    <password-strength password="${this.pw}"></password-strength><br>
                    <vaadin-password-field  @input="${this.pwConfirmListener}" name="PasswortConfirmation"
                      id="PasswortConformationField" minlength="8" label="Passwort Bestätigung" maxlength="32" required reveal-button-hidden></vaadin-password-field><br>

                    <br>

                    <vaadin-button
                      ?disabled=${!this.pwValid()}
                      theme="primary"
                      @click="${this.savePW}"  style="margin: 20px 0px">
                        Speichern
                    </vaadin-button>
                    <div style="height:20px"> </div>
                </vaadin-form-layout>
              </form>
    `;
  }

  pwListener(e) {
    this.pw = e.target.value;
  }

  pwConfirmListener(e) {
    this.pwConfirm = e.target.value;
  }

  pwValid() {
    if (this.pw == this.pwConfirm && this.pw != null && this.pw.length > 7) {
      return true;
    }
    return false;
  }

  savePW() {
    store.dispatch(updateOwnPW({password: this.pw, token: this.token}, this.recovermode));
  }
}

window.customElements.define('benutzerprofil-view', BenutzerprofilView);
